import { Tooltip } from '@unionco/components'
import tw from 'twin.macro'

import type { IInfoData } from '@unionco/alaris-app-types'

import {
  TModuleTrackingName,
  useNavigationClickTracking
} from 'hooks/useNavigationClickTracking'

import { ModuleCard } from 'components'

export interface IInfoCardHeader {
  className?: string
  info?: IInfoData
  title?: string
  trackingName: TModuleTrackingName
}

export type TInfoCardHeaderComponent = React.FC<IInfoCardHeader>

/**
 * InfoCardHeader component
 *
 * @param className string optional classNames to alter default UI styling
 * @param info @see IInfoData The content revealed when the tooltip is used
 * @param title string the display name of the module shown in UI
 * @param trackingName @see TModuleTrackingName the name sent to google analytics
 *
 * @returns Card Header UI specific for modules (primarly used for profile modules)
 */
export const InfoCardHeader: TInfoCardHeaderComponent = ({
  className,
  info,
  title = '',
  trackingName
}) => {
  const { navTrackingOnClick } = useNavigationClickTracking()
  return (
    <ModuleCard.Header className={`print:u-break-after-avoid-page ${className}`} title={title}>
      {info && (
        <Tooltip
          copy={info.copy}
          iconColorClass={tw`u-text-primary-500`}
          revealCallback={() =>
            navTrackingOnClick('info tooltip', trackingName)
          }
        />
      )}
    </ModuleCard.Header>
  )
}
export default InfoCardHeader
