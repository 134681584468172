/** Titled List Item
 *  This is similar to Key Value Text component
 *  It dislays a title horizontall next to the copy
 */
import { cx } from '@unionco/utils'

interface ITitledListItemProps {
  className?: string
  copy: string
  title: string
}

export const TitledListItem: React.FC<ITitledListItemProps> = ({
  className,
  copy,
  title
}) => {
  return (
    <div className={cx(`b-TitledListItem c-cluster`, className)}>
      <p className='o-body-1 u-font-semibold u-text-secondary-900 u-text-wrap'>{title}</p>
      <p className='o-body-1 u-text-wrap'>{copy}</p>
    </div>
  )
}

export default TitledListItem
