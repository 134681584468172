import { PadLockIcon } from 'components'

interface ILockedUIProps {
  stepTitle: string
}

export const LockedUI: React.FC<ILockedUIProps> = ({ stepTitle }) => {
  return (
    <div className='u-flex u-items-center u-justify-center u-bg-secondary-200 u-p-8'>
      <div className='u-flex u-items-center'>
        <div className='u-mr-4 u-flex u-h-8 u-w-8 u-items-center u-justify-center u-bg-primary-500'>
          <PadLockIcon className='u-text-white' />
        </div>
        <p className='o-body-1'>
          Unlocks after <span className='u-uppercase'>{stepTitle}</span>
        </p>
      </div>
    </div>
  )
}

export default LockedUI
