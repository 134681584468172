import { CaretIcon } from '@unionco/svg-icons'
import { signOut } from 'next-auth/react'
import type { FC } from 'react'
import { useContext, useRef, useState } from 'react'
import { useClickAnyWhere } from 'usehooks-ts'

import type { TAuthUserData } from '@unionco/alaris-app-types'

import {
  AdminPanelPageSlug,
  DashboardPageSlug,
  type TPageName
} from '@appConstants'

import { IAMContext, TIAMContext, UserContext } from 'context'

import { useNavigationClickTracking } from 'hooks/useNavigationClickTracking'

import { StyledButton } from 'components'
import SupportLink from 'components/styledButtons/SupportLink'

interface IAccountDropdownProps {
  mobileLayout: boolean
  pageName: TPageName
}

/**
 * Account Dropdown Component
 * Menu Contains:
 * - Link back to the users dashboard
 * - Link to the support page on mobile
 * - Exit impersonation button when admins are in either presentation or impersonation (assessment) modes
 * - Logout button
 *
 * @param mobileLayout: boolean that informs the component if the application is in a mobile screen size, and adds
 * a link to the support page if true
 * @param pageName: @see TPageName a human readable string for the page name passed to the
 * nav tracking function for when the menu is opened
 *
 * @returns a user account dropdown
 */
export const AccountDropdown: FC<IAccountDropdownProps> = ({
  mobileLayout,
  pageName
}) => {
  const { id } = useContext(UserContext) as TAuthUserData
  const {
    IAMData: { firstName, impersonationMode, lastName, userType },
    impersonateUser
  } = useContext(IAMContext) as TIAMContext
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const menuRef = useRef<HTMLDivElement>(null)
  const firstNameInitial = firstName ? Array.from(firstName)[0] : ''
  const lastNameInitial = lastName ? Array.from(lastName)[0] : ''
  const { navTrackingOnClick } = useNavigationClickTracking()

  const toggleIsOpen = (
    isOpen: boolean,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation()
    const newState = !isOpen
    setIsOpen(newState)

    if (isOpen) {
      navTrackingOnClick('account dropdown', pageName)
    }
  }

  useClickAnyWhere((e) => {
    if (!menuRef.current) return
    const { current } = menuRef
    const { target } = e
    if (target && !current.contains(target as HTMLElement)) {
      setIsOpen(false)
    }
  })

  return (
    <div id='accountDropdown' className='u-relative'>
      <button
        id='accountDropdown_btn'
        className='c-cluster | u-cluster-gutter--200'
        onClick={(e) => toggleIsOpen(isOpen, e)}
      >
        <abbr className='u-text-uppercase u-flex u-h-7 u-w-7 u-items-center u-justify-center u-rounded-full u-bg-black u-text-white'>
          {firstNameInitial}
          {lastNameInitial}
        </abbr>
        <span>
          {firstName} {lastNameInitial}
          {lastNameInitial && '.'}
        </span>
        <CaretIcon className='u-text-secondary-500' />
      </button>
      {isOpen && (
        <div
          id='accountDropdown_menu'
          className='u-absolute u-right-0 u-z-10 u-rounded u-bg-white u-py-2 u-shadow'
          ref={menuRef}
          onClick={(e) => e.stopPropagation}
          style={{
            top: 'calc(100% + 10px)'
          }}
        >
          <ul className='u-flex u-flex-col u-items-center'>
            <li className='o-body-1'>
              <StyledButton
                id='homeButton'
                type='a'
                theme='secondary'
                themeStyle='ghost'
                href={
                  userType === 'admin' ? AdminPanelPageSlug : DashboardPageSlug
                }
                className='u-justify-center'
                onClick={() =>
                  navTrackingOnClick('dashboard', 'account dropdown')
                }
              >
                Dashboard
              </StyledButton>
            </li>
            {mobileLayout && (
              <li className='o-body-1'>
                <SupportLink theme='dark' />
              </li>
            )}
            {impersonationMode && (
              <li className='o-body-1'>
                <StyledButton
                  type='a'
                  theme='secondary'
                  themeStyle='ghost'
                  onClick={() => impersonateUser(id, null)}
                  href={AdminPanelPageSlug}
                  className='u-block'
                >
                  Exit impersonation
                </StyledButton>
              </li>
            )}
            <li className='o-body-1'>
              <StyledButton
                id='accountDropdown_signOut'
                type='button'
                theme='secondary'
                themeStyle='ghost'
                onClick={() => signOut()}
              >
                Sign Out
              </StyledButton>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}

export default AccountDropdown
