import { RefObject, useContext } from 'react'

import { TPhaseStepSummaryData } from '@unionco/alaris-app-types'
import { IAMContext, TIAMContext } from 'context'
import { getPhaseStepPercent } from 'utils'
import { getDateTimeText } from './utils'

import { StyledButton } from 'components/generic'

import { TimelineLine } from './TimelineLine'
import TimelineItem from './Timelineitems'

interface ITimelineProgressionUIProps {
  currentSectionIndex: number
  spaceBetweenCircles: number[]
  statusCircleRadius: number
  title: string
  timelineCircleRefs: RefObject<HTMLDivElement>[] | null
  timelineItems: TPhaseStepSummaryData[]
}

export const TimelineProgressionUI: React.FC<ITimelineProgressionUIProps> = ({
  currentSectionIndex,
  spaceBetweenCircles,
  statusCircleRadius,
  timelineCircleRefs,
  timelineItems,
  title
}) => {
  const lastStepIndex = timelineItems.length - 1
  const {
    IAMData: { companyId }
  } = useContext(IAMContext) as TIAMContext
  return (
    <div className='c-flow | u-rounded u-bg-white u-px-700 u-py-500'>
      <div className='c-dep-repel'>
        <h2 className='o-h2'>{title}</h2>
        <StyledButton
          href='https://alarisacquisitions.com/wp-content/uploads/2021/09/01-Main-1.mp4' // TODO: Replace with link to user timeline on live website
          type='a'
          themeStyle='outline'
          theme='secondary'
          target='_blank'
        >
          View Full Timeline
        </StyledButton>
        <StyledButton
          type='a'
          href={`/cim?companyId=${companyId}`}
          theme='secondary'
          themeStyle='outline'
        >
          View your firm&apos;s profile
        </StyledButton>
      </div>
      <div className='c-withLayer | u-flex u-justify-between'>
        {timelineItems.map((summary, index) => {
          const { key: stepKey, status, title, type } = summary
          const percent = getPhaseStepPercent(type, status)
          const usePercent = percent && percent !== 0

          return (
            <div className='c-withLayer' key={`${title}_${index}`}>
              {index !== lastStepIndex && (
                <TimelineLine
                  itemHalfWidth={statusCircleRadius}
                  spaceBetween={spaceBetweenCircles[index]}
                  useActive={usePercent}
                  percent={percent}
                />
              )}
              <TimelineItem
                ref={timelineCircleRefs && timelineCircleRefs[index]}
                currentSectionIndex={currentSectionIndex}
                dateTimeText={getDateTimeText(summary)}
                index={index}
                stepKey={stepKey}
                {...summary}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}
