import { Card } from '@unionco/components'
import { useContext } from 'react'

import { ILocationsIndexesData } from '@unionco/alaris-app-types'

import { validNum } from '@unionco/utils'

import { IAMContext, TIAMContext } from 'context'

import { getSidebarRoute } from 'components/SidebarTemplate'
import { StyledButton } from 'components/generic'

interface IResumeCalloutProps {
  pageName: string
  progressionIndexes: ILocationsIndexesData
  lastLocationIndexes: ILocationsIndexesData
  sectionKey: string
  showOnInitialLoad: boolean
}

export const ResumeCallout: React.FC<IResumeCalloutProps> = ({
  lastLocationIndexes: {
    question: lastQuestion,
    section: lastStep,
    segment: lastSegment
  },
  pageName,
  progressionIndexes: {
    question: progQuestion,
    section: progStep,
    segment: progSegment
  },
  sectionKey,
  showOnInitialLoad
}) => {
  const {
    IAMData: { userType, impersonationMode }
  } = useContext(IAMContext) as TIAMContext
  const locationDiff =
    (validNum(lastStep) && progStep !== lastStep) ||
    (validNum(lastSegment) && progSegment !== lastSegment) ||
    lastQuestion ||
    (lastQuestion === 0 && progQuestion !== lastQuestion) ||
    showOnInitialLoad

  if (!locationDiff && !impersonationMode) return <></>

  const slug = getSidebarRoute(pageName, sectionKey, lastSegment, lastQuestion)

  return (
    <Card className='c-dep-repel | u-p-400'>
      <p className='o-body-1'>
        <span className='u-font-bold'>Hey!</span> Want to pick up where you left
        off? Click &apos;continue&apos; on the right.
      </p>
      <StyledButton type='a' href={`${userType}/${slug}`}>
        Continue
      </StyledButton>
    </Card>
  )
}

export default ResumeCallout
