/** Acquistion Model Module
 *  Buyer Profile Sidebar Module
 *  Displays list of information on how buyers
 *  handle acquisition process. Each title of
 *  a given list item has it's own info component,
 *  where as the module itself does not.
 *
 *  Design shows:
 *  Acquisition Type, Requires Brand Adoption, Max Cash,
 *  Max Equity, Has Earn-out, Permits BD Affiliation,
 *  Permits IM Autonomy, Permits 1099's, Requires CX adoption,
 *  Share of ongoing economics.
 *
 *  Each with it's own "answer" below the title
 */
import type { IAcquisitionModelData } from '@unionco/alaris-app-types'

import { capitalizeAndSplit } from '@unionco/utils'

import { useNavigationClickTracking } from 'hooks/useNavigationClickTracking'

import { ModuleCard, TitledList } from 'components'

interface IAcquisitionModelProps {
  className?: string
  data: IAcquisitionModelData
}

export const AcquisitionModel: React.FC<IAcquisitionModelProps> = ({
  className,
  data: {
    acquisitionTypeTooltip,
    items,
    sharesOngoingEconomicsTooltip,
    maxCashTooltip,
    maxEquityTooltip,
    requiresBrandAdoptionTooltip,
    retentionTermsTooltip,
    title
  }
}) => {
  const { navTrackingOnClick } = useNavigationClickTracking()

  const getDisplayName = (key: string) => {
    return capitalizeAndSplit(key)
  }

  const getTooltip = (key: string) => {
    const cappedKey = `${key.charAt(0).toUpperCase()}${key.slice(1)}`
    switch (cappedKey) {
      case 'AcquisitionType':
        return acquisitionTypeTooltip
      case 'MaxCash':
        return maxCashTooltip
      case 'MaxEquity':
        return maxEquityTooltip
      case 'RequiresBrandAdoption':
        return requiresBrandAdoptionTooltip
      case 'RetentionTerms':
        return retentionTermsTooltip
      case 'SharesOngoingEconomics':
        return sharesOngoingEconomicsTooltip
      default:
        return null
    }
  }

  const liItems = items.map((item) => {
    const { title } = item
    return {
      ...item,
      info: getTooltip(title),
      title: getDisplayName(title)
    }
  })

  //remove "Requires Brand Adoption" from list
  const filteredListItems = liItems.filter(item => item.title !== "Requires Brand Adoption")

  filteredListItems.map((item) => {
    const { title } = item
    // if(title !== "Requires Brand Adoption")
    return {
      ...item,
      info: getTooltip(title),
      title: getDisplayName(title)
    }
  })

  // alphabetize liItems
  filteredListItems.sort((a, b) => {
    const aTitle = a.title.toLowerCase()
    const bTitle = b.title.toLowerCase()
    if (aTitle < bTitle) return -1
    if (aTitle > bTitle) return 1
    return 0
  })


  if (!filteredListItems || filteredListItems.length === 0) return <></>

  return (
    <ModuleCard.Body className={`u-text-left ${className}`}>
      <ModuleCard.Header title={title || 'Acquisition Model'} />
      <TitledList
        items={filteredListItems}
        composition='c-cluster'
        infoRevealCallback={() =>
          navTrackingOnClick('info tooltip', 'acquisition model module')
        }
      />
    </ModuleCard.Body>
  )
}

export default AcquisitionModel
