/** FAQ Section Item
 *  Accordion item specific to FAQ Section component
 */
import { Accordion, AccordionContext } from '@unionco/components'
import { ChevronIcon } from '@unionco/svg-icons'
import { useContext } from 'react'

import type { ITitleAndCopyData } from '@unionco/alaris-app-types'

import { isAdminTracking } from 'utils/tracking'
import { dropdownToggleTracking } from 'utils/tracking/dropdown'

import { TUserContext, UserContext } from 'context'

interface IFAQSectionItemProps {
  data: ITitleAndCopyData
  index: number
}

export const FAQSectionItem: React.FC<IFAQSectionItemProps> = ({
  data,
  index
}) => {
  const { openItems } = useContext(AccordionContext)
  const iconColor =
    openItems && openItems.includes(index)
      ? 'u-text-primary-500'
      : 'u-text-secondary-500'
  const { userType } = useContext(UserContext) as TUserContext

  function trackingOnClick() {
    dropdownToggleTracking({
      element_name: data.title,
      is_admin_key: isAdminTracking(userType)
    })
  }
  return (
    <>
      <Accordion.Item index={index} className='u-text-secondary-500'>
        <Accordion.Button
          index={index}
          locked={false}
          className='c-dep-repel o-subtitle-1 u-py-400 u-transition-colors'
          activeClassName='!u-text-black'
          callBack={trackingOnClick}
        >
          {data.title}
          <Accordion.Indicator index={index} animation='downToRight'>
            <ChevronIcon
              iconStyle='solid'
              iconDirection='right'
              className={iconColor}
            />
          </Accordion.Indicator>
        </Accordion.Button>
        <Accordion.Drawer index={index}>
          <div className='u-pb-400'>{data.copy}</div>
        </Accordion.Drawer>
      </Accordion.Item>
      <div className='o-line u-bg-grayscale-600' />
    </>
  )
}

export default FAQSectionItem
