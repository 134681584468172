import React, { useState, useEffect } from 'react';
import { Divider } from 'components';
import { StyledButton } from 'components/generic';
import { IMatchConfiguration } from '@unionco/alaris-app-types';

export interface IFormDataProps {
    isAdmin: boolean;
    title: string;
    configuration: IMatchConfiguration;
    handleSubmit: (configuration: IMatchConfiguration) => void;
}

export const ConfigureMatchForm: React.FC<IFormDataProps> = ({
    configuration = { compatible: true, compatibleReason: '', tiltConfiguration: [], adminOverride: false, overrideCompatibility: false },
    handleSubmit,
    title
}) => {
    const [updatedConfiguration, setConfiguration] = useState<IMatchConfiguration>(configuration);
    const [error, setError] = useState(false);
    const [invalidCategories, setInvalidCategories] = useState<string[]>([]);

    useEffect(() => {
        const updatedTiltConfigurations = [...(updatedConfiguration.tiltConfiguration || [])];
        setConfiguration(prevConfig => ({
            ...prevConfig,
            tiltConfiguration: updatedTiltConfigurations
        }));
    }, [title]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, field: keyof IMatchConfiguration) => {
        setError(false);
        const update = { ...updatedConfiguration };

        switch (field) {
            case 'tiltConfiguration':
                const tiltConfigValue = [...(update.tiltConfiguration ?? [])];
                const existingIndex = tiltConfigValue.findIndex(config => config.tiltCategory === title);

                if (event.target.type === "radio") {
                    const newValue = Number(event.target.value);
                    if (existingIndex !== -1) {
                        tiltConfigValue[existingIndex].tilt = newValue;
                    } else {
                        tiltConfigValue.push({ tilt: newValue, tiltCategory: title, tiltReason: '' }); // Create new object if no existing index
                    }
                } else if (event.target.type === "text") {
                    const newValue = event.target.value;
                    if (existingIndex !== -1) {
                        tiltConfigValue[existingIndex].tiltReason = newValue;
                    } else {
                        tiltConfigValue.push({ tilt: 0, tiltCategory: title, tiltReason: newValue }); // Create new object if no existing index, default tilt to 0
                    }
                }

                update.tiltConfiguration = tiltConfigValue;
                break;
            case 'compatible':
                update.overrideCompatibility = event.target.value === "true";
                update.adminOverride = true;
                update.compatible = update.overrideCompatibility;
                break;
            case 'compatibleReason':
                update.compatibleReason = event.target.value;
                break;
            default:
                break;
        }

        setConfiguration(update);
    };

    const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const invalidCategories: string[] = [];
        let tiltReasonMissing = false;
        let compatibleReasonMissing = false;

        // Check if any of the tilt configurations are greater than 0 and if they have a reason
        updatedConfiguration.tiltConfiguration?.forEach(config => {
            if (config.tilt > 0 && !config.tiltReason) {
                tiltReasonMissing = true;
                invalidCategories.push(config.tiltCategory);
            }
        });

        // Check if the compatible reason is missing
        if (!updatedConfiguration.compatibleReason && !updatedConfiguration.compatible) {
            compatibleReasonMissing = true;
        }

        // Add tiltCategory to invalidCategories only if it wasn't added by missing tiltReason
        if (compatibleReasonMissing && !tiltReasonMissing) {
            invalidCategories.push('Incompatible Reason');
        }

        // Set error if any reason is missing
        if (tiltReasonMissing || compatibleReasonMissing) {
            setError(true);
            setInvalidCategories(invalidCategories);
            return;
        }

        // If no errors, set error to false and submit the form
        setError(false);
        handleSubmit(updatedConfiguration);
    };

    //array of percentage values for tilt that is iterated over to create radio buttons
    const tiltPercentages = [-100, -75, -50, -25, 0, 25, 50, 75, 100];

    return (
        <form className="u-mb-0 u-p-2 u-pb-0" onSubmit={handleFormSubmit}>
            <div className="u-flex u-w-full u-justify-between ">
                <div className="u-w-1/2 u-pl-5 u-p-2 ">
                    {/* Compatibility Configuration */}
                    <Divider title="Compatibility" lineClasses='[--line-color:theme(colors.secondary.300)]' className="u-mb-3 u-w-full" />
                    <div className="u-flex u-flex-col">
                        <label className="u-flex u-items-center">
                            <input
                                className="u-aspect-square u-h-[1.875rem] u-w-[1.875rem] u-rounded-full u-mr-4"
                                type="radio"
                                name="compatible"
                                value="true"
                                onChange={(event) => handleChange(event, 'compatible')}
                                checked={updatedConfiguration.compatible === true}
                            />
                            Compatible
                        </label>
                        <label className="u-flex u-items-center">
                            <input
                                className="u-aspect-square u-h-[1.875rem] u-w-[1.875rem] u-rounded-full u-mr-4"
                                type="radio"
                                name="compatible"
                                value="false"
                                onChange={(event) => handleChange(event, 'compatible')}
                                checked={updatedConfiguration.compatible === false}
                            />
                            Incompatible
                        </label>
                    </div>
                    {/* Incompatible Reason Text Area */}
                    {updatedConfiguration.compatible === false && (
                        <div>
                            <label className="o-overline u-text-grayscale-500">Explain Why this match is incompatible:</label>
                            <input
                                type="text"
                                name="compatibleReason"
                                value={updatedConfiguration.compatibleReason}
                                onChange={(event) => handleChange(event, 'compatibleReason')}
                            />
                        </div>
                    )}
                </div>
                <div className="u-w-1/2 u-pr-5 u-p-2 ">
                    {/* Tilt Configuration */}
                    <Divider title={`${title} Tilt Factor`} lineClasses='[--line-color:theme(colors.secondary.300)]' className="u-mb-3" />
                    <div className="u-grid u-grid-cols-3 u-gap-1 u-mb-2">
                        {tiltPercentages.map(value => (
                            <label key={value} className="u-flex u-items-center">
                                <input
                                    type="radio"
                                    className="u-aspect-square u-h-[1.875rem] u-w-[1.875rem] u-rounded-full u-mr-4"
                                    name="tilt"
                                    value={value}
                                    onChange={(event) => handleChange(event, 'tiltConfiguration')}
                                    checked={
                                        (updatedConfiguration.tiltConfiguration?.find(config => config.tiltCategory === title)?.tilt ?? 0) === value
                                    } />
                                {value}%
                            </label>
                        ))}
                    </div>
                    {/* Tilt Reason Text Area */}
                    {(updatedConfiguration.tiltConfiguration?.find(config => config.tiltCategory === title)?.tilt ?? 0) !== 0 && (
                        <div>
                            <label className="o-overline u-text-grayscale-500">Explain Reason for Tilt:</label>
                            <input
                                type="text"
                                name="tiltReason"
                                value={updatedConfiguration.tiltConfiguration?.find(config => config.tiltCategory === title)?.tiltReason || ''}
                                onChange={(event) => handleChange(event, 'tiltConfiguration')}
                            />
                        </div>
                    )}
                </div>
                <div className="u-flex u-justify-items-end u-mt-2 u-flex-col">
                    <StyledButton
                        type='submit'
                        theme='secondary'
                        themeStyle='solid'
                        className='u-flex u-w-full u-max-w-[13.125rem] u-justify-center u-text-center hover:u-bg-primary-500 hover:u-text-white'
                    >
                        Submit
                    </StyledButton>
                    <div className='u-flex u-flex-col'>
                        {error ? <p className='o-body-1'>Please provide additional details for {invalidCategories.join(', ')}.</p> : <p className='o-body-1'>{'\u00A0'}</p>}
                    </div>
                </div>
            </div>
        </form>
    );
}

export default ConfigureMatchForm;
