import { Accordion } from '@unionco/components'
import { ChevronIcon } from '@unionco/svg-icons'
import React from 'react'

import { IDataRoomData } from '@unionco/alaris-app-types'

import { ModuleCard } from 'components'

import DownloadListItem from './DownloadListItem'

interface IDataRoomProps {
  data: IDataRoomData
}

export const DataRoom: React.FC<IDataRoomProps> = ({
  data: { info, items: { items } = {}, title }
}) => {
  if (!items || items.length === 0) return <></>

  const numOfCategories = items.length
  return !!items && numOfCategories !== 0 ? (
    <ModuleCard.Body className='u-text-left print:u-hidden'>
      <ModuleCard.InfoHeader
        info={info}
        title={title || 'Data Room'}
        trackingName='data room module'
      />
      <Accordion.Main>
        {items.map((section, index) => {
          const { items, title } = section
          const padding = index === numOfCategories - 1 ? 'u-pt-5' : 'u-py-5'

          const workingLinks =
            items === null
              ? []
              : items.filter((i) => {
                if (!i.href) return false
                return i.href !== '#'
              })

          if (workingLinks.length === 0)
            return <React.Fragment key={`Fragment_${index}`} />

          return (
            <Accordion.Item index={index} key={`DataRoomList_${index}`}>
              {index !== 0 && <div className='o-line' />}
              <Accordion.Button
                activeClassName='!u-text-black'
                className={`c-dep-repel u-text-secondary-800 ${padding}`}
                index={index}
                locked={false}
              >
                <p className='o-body-1 o-subtitle-1 u-text-current'>{title}</p>
                <Accordion.Indicator index={index} animation='downToUp'>
                  <ChevronIcon iconStyle='solid' iconDirection='down' />
                </Accordion.Indicator>
              </Accordion.Button>
              <Accordion.Drawer index={index}>
                {workingLinks.map((item, index) => {
                  return (
                    <React.Fragment key={`DataRoomListItem_${index}`}>
                      {index !== 0 && (
                        <div className='o-line u-bg-primary-300' />
                      )}
                      <DownloadListItem {...item} />
                    </React.Fragment>
                  )
                })}
              </Accordion.Drawer>
            </Accordion.Item>
          )
        })}
      </Accordion.Main>
    </ModuleCard.Body>
  ) : (
    <></>
  )
}

export default DataRoom
