/** Login Card Content
 *  If session exists user is logged in and given
 *  link to go to dashboard
 *
 *  If there is no session, user is unauthenticated
 *  by default load the send email form.
 *
 *  After submit button is hit,
 *  if email is sent, display confirmation message guiding user to
 *  check their email to sign in.
 *  If account does not exist OR email failed to send, display error
 *  message.
 */
import { EmailInput } from '@unionco/components'
import { useSession } from 'next-auth/react'
import Link from 'next/link'
import type { FC, FormEvent } from 'react'
import { useContext } from 'react'

import { helperTracking } from 'utils/tracking/help'

import { PreAuthGlobalsContext } from 'context'

import { ErrorUI, StyledButton } from 'components'

import WelcomeContent from './WelcomeContent'

interface ILoginContentProps {
  emailInputId: string
  emailSent: boolean | undefined
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>
  resetEmailSent: () => void
}

export const LoginContent: FC<ILoginContentProps> = ({
  emailInputId,
  emailSent,
  onSubmit,
  resetEmailSent
}) => {
  const { data: session } = useSession()
  const {
    contactEmail,
    loginPageEmailError,
    loginPageEmailSuccess,
    loginPageLoggedIn,
    loginPageLoginForm
  } = useContext(PreAuthGlobalsContext)

  function trackingOnClick() {
    helperTracking({
      click_type: 'email',
      is_admin_key: 'false'
    })
  }

  if (session) {
    if (!loginPageLoggedIn)
      return <ErrorUI text='Error loading login content' />
    return <WelcomeContent data={loginPageLoggedIn} />
  }
  switch (emailSent) {
    case true: {
      /** Email Sent Content
       *
       */
      if (!loginPageEmailSuccess)
        return <ErrorUI text='Error loading login content' />
      const { buttonText, copy, title } = loginPageEmailSuccess
      return (
        <div className='c-flow | u-text-center'>
          {title && <h2 className='o-h2'>{title}</h2>}
          {copy && <p className='o-body-1'>{copy}</p>}
          <StyledButton
            className='u-self-center'
            type='button'
            theme='secondary'
            themeStyle='ghost'
            onClick={resetEmailSent}
          >
            {buttonText || 'To login'}
          </StyledButton>
        </div>
      )
    }
    case false: {
      /** Missing Account Content
       *
       */
      if (!loginPageEmailError)
        return <ErrorUI text='Error loading login content' />
      const { buttonText, copy, title } = loginPageEmailError
      return (
        <div className='c-flow | u-text-center'>
          {title && <h2 className='o-h2'>{title}</h2>}
          {copy && <p className='o-body-1 u-text-state-danger'>{copy}</p>}
          <StyledButton
            className='u-self-center'
            type='button'
            theme='secondary'
            themeStyle='ghost'
            onClick={resetEmailSent}
          >
            {buttonText || 'To login'}
          </StyledButton>
        </div>
      )
    }
    case undefined:
    default: {
      /** Login Form Content
       *
       */
      if (!loginPageLoginForm)
        return <ErrorUI text='Error loading login content' />
      const { buttonText, copy, title } = loginPageLoginForm
      return (
        <>
          <div className='c-flow | u-text-center'>
            {title && <h2 className='o-h2'>{title}</h2>}
            <p className='o-body-1'>{copy || 'Please enter your email below'}</p>
          </div>
          <form
            className='c-flow | u-flow-space--200 u-mt-500'
            onSubmit={onSubmit}
          >
            <EmailInput id={emailInputId} className='u-max-w-[20.5rem]' />
            <StyledButton type='submit' large className='u-flow-space--500'>
              {buttonText || 'Log In'}
            </StyledButton>
          </form>
          <div className='c-flow | u-flow-space--400 | u-mt-600 u-text-center'>
            {/* TODO: Get phone number for get in touch (or email address) */}
            {!!contactEmail && (
              <p className='o-body-1'>
                Don&apos;t have an account?{' '}
                <Link href={`mailto:${contactEmail}`} onClick={trackingOnClick}>
                  Get in touch
                </Link>
              </p>
            )}
          </div>
        </>
      )
    }
  }
}

export default LoginContent
