import { TSVGProps } from '@unionco/alaris-app-types';

interface IBulletIconProps extends TSVGProps {
}

const Bullet: React.FC = ({ ...props }) => {
    return (
        <div className='u-flex u-items-center u-py-[7px] u-rounded-full u-justify-center'>
            <div
                className="u-rounded-full u-flex u-items-center u-justify-center"
                style={{
                    width: '16px',
                    height: '16px',
                    backgroundColor: 'rgba(65, 116, 232, 0.20)',
                }}
            >
                <svg
                    width='10'
                    height='10'
                    viewBox='0 0 10 10'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    {...props}
                >
                    <circle cx="5" cy="5" r="5" fill="#4174E8" />
                </svg>
            </div>
        </div>
    );
};

export const BulletIcon: React.FC<IBulletIconProps> = ({
    ...props
}) => {
    return <Bullet {...props} />
}

export default BulletIcon
