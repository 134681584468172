/** FAQCardItem
 *  Accordion item specific to accordion for FAQ Card
 */
import { Accordion, AccordionContext } from '@unionco/components'
import { PlusMinusIcon } from '@unionco/svg-icons'
import { useContext, useRef } from 'react'

import type { IFAQContentModuleData } from '@unionco/alaris-app-types'

import { isAdminTracking } from 'utils/tracking'
import { dropdownToggleTracking } from 'utils/tracking/dropdown'

import { TUserContext, UserContext } from 'context'

import { useVimeo } from 'hooks/useVimeo'

import VideoRunTime from 'components/generic/Video/VideoRuntime'

import { FAQCardContent } from './content'

interface IFAQCard {
  data: IFAQContentModuleData
  index: number
}

export const FAQCardItem: React.FC<IFAQCard> = ({ data, index }) => {
  const itemRef = useRef<HTMLDivElement>(null)
  const { runTime } = useVimeo(itemRef)
  const { title } = data
  const { itemIsOpen } = useContext(AccordionContext)
  const isOpen = itemIsOpen(index)
  const buttonColor = isOpen ? 'u-text-primary-500' : 'u-text-secondary-500'

  const runTimeUI =
    data.moduleType === 'video' ? <VideoRunTime runTime={runTime} /> : <></>
  const { userType } = useContext(UserContext) as TUserContext

  function trackingOnClick() {
    dropdownToggleTracking({
      element_name: title ? `${title}` : 'No title',
      is_admin_key: isAdminTracking(userType)
    })
  }

  return (
    <Accordion.Item index={index} className='u-py-400'>
      <Accordion.Button
        index={index}
        locked={false}
        className='c-dep-repel | u-flex-nowrap'
        callBack={trackingOnClick}
      >
        <div className='c-cluster'>
          <p className='o-body-1 u-text-5 u-font-bold'>{title}</p>
          {runTimeUI}
        </div>
        <span className={`u-flex u-aspect-square u-w-[2rem] ${buttonColor}`}>
          <Accordion.Indicator index={index}>
            <PlusMinusIcon className='u-w-full' open={!isOpen} />
          </Accordion.Indicator>
        </span>
      </Accordion.Button>
      <Accordion.Drawer index={index}>
        <div ref={itemRef}>
          <FAQCardContent data={data} />
        </div>
      </Accordion.Drawer>
    </Accordion.Item>
  )
}

export default FAQCardItem
