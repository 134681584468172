/** FAQ Section UI
 *  FAQ UI data styled and formatted for sidebar
 */
import { Accordion } from '@unionco/components'
import Link from 'next/link'
import { useContext } from 'react'

import type { IFAQSectionData } from '@unionco/alaris-app-types'

import { SupportPageSlug } from 'appConstants'

import { isAdminTracking } from 'utils/tracking'
import { helperTracking } from 'utils/tracking/help'

import { TUserContext, UserContext } from 'context'

import { Video } from 'components'

import FAQSectionItem from './Item'

interface IFAQSectionUIProps {
  data: IFAQSectionData
}

export const FAQSectionUI: React.FC<IFAQSectionUIProps> = ({
  data: { copy, eyebrow, faqs, video }
}) => {
  const useTopSection = !!copy || !!eyebrow || !!video
  const { userType } = useContext(UserContext) as TUserContext

  function trackingOnClick() {
    helperTracking({
      click_type: 'going to support page',
      is_admin_key: isAdminTracking(userType)
    })
  }

  if (!faqs || faqs.length === 0) return <></>

  return (
    <div className='b-FAQsection c-flow'>
      {useTopSection && (
        <div className='c-flow | u-mb-500'>
          {eyebrow && <p className='o-body-1 o-overline'>{eyebrow}</p>}
          {video && <Video data={video} />}
          {copy && <p className='o-body-1 u-flow-space--400'>{copy}</p>}
        </div>
      )}
      <p className='o-body-1 o-overline'>Related FAQS</p>
      <Accordion.Main className='u-mt-2'>
        {faqs.map((faq, index) => {
          const { key } = faq
          return <FAQSectionItem data={faq} index={index} key={key} />
        })}
      </Accordion.Main>
      <p className='o-body-1 u-flow-space--500 u-text-center'>
        Still have questions?{' '}
        <Link href={SupportPageSlug} onClick={trackingOnClick}>
          Get Help
        </Link>
      </p>
    </div>
  )
}

export default FAQSectionUI
