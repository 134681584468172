/** Key Value Text
 *  Displays
 *  `${key}: ${value}` || `${key}: ${icon} ${value}`
 *  Or a display component that looks like an objects
 *  key value pair, with an icon preceeding the value text.
 *
 *  Contextually used to display strings like
 *  `Estimated Duration: ${clockIcon} 15min`
 *
 *  This component is likely to move up the component tree since it
 *  likely has other use cases outside of the time
 */
// import type { TIconName } from 'components'
import type { TIconName } from '@unionco/alaris-app-types'

import { Icon } from 'components'

export interface IKeyValueTextProps {
  dateTimeText: string
  iconName: TIconName
  keyText: string
}

export const KeyValueText: React.FC<IKeyValueTextProps> = ({
  dateTimeText,
  iconName,
  keyText
}) => {
  return (
    <p className='o-body-1 u-flex u-justify-center u-text-center'>
      <span className='u-mr-2'>{keyText}:</span>
      <span className='u-flex u-items-center u-text-primary-500'>
        {iconName && <Icon className='u-mr-1 u-block' name={iconName} />}
        <span>{dateTimeText}</span>
      </span>
    </p>
  )
}

export default KeyValueText
