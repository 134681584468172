import type { FC } from 'react'

import { IInputValueProfilePic } from '@unionco/alaris-app-types'

import { Img } from 'components/generic'

interface IProfilePicReviewProps {
  img: IInputValueProfilePic
}

export const ProfilePicReview: FC<IProfilePicReviewProps> = ({ img }) => {
  if (!img || !img.value || !img.value.value || img.value.value === '')
    return <></>

  return (
    <>
      <p className='o-body-1'>
        <span>Profile Pic: </span>
      </p>
      <Img src={img.value.value} alt='' width={50} height={50} />
    </>
  )
}
