/** Check Mark List Item
 *  Displays a circle next to some text content
 *  ( ) Content || (X) Content - where x is a checkmark icon
 *  Displays a different color border for non-checked items
 */
import { CheckMarkIcon } from '@unionco/svg-icons'

import type { ICheckMarkItemData } from '@unionco/alaris-app-types'

import { cx } from '@unionco/utils'

interface ICheckMarkListItemProps {
  className?: string
  data: ICheckMarkItemData
  titleClassName?: string
}

export const CheckMarkListItem: React.FC<ICheckMarkListItemProps> = ({
  className,
  data: { checked, subText, title },
  titleClassName
}) => {
  const styles = checked
    ? 'u-border-primary-500 u-bg-primary-500'
    : 'u-border-secondary-800'

  return (
    <div className={cx(className)}>
      <div className='u-flex u-items-center'>
        <div
          className={`u-mr-4 u-flex u-aspect-square u-w-6 u-overflow-hidden u-rounded-full u-border ${styles}`}
        >
          {checked && (
            <div className='u-flex u-items-center u-justify-center'>
              <CheckMarkIcon className='u-w-3/5 u-text-white' curved />
            </div>
          )}
        </div>

        <p className={`o-body-1 u-font-bold ${titleClassName}`}>{title}</p>
      </div>
      {subText && <p className='o-body-1 u-ml-10 u-text-3'>{subText}</p>}
    </div>
  )
}

export default CheckMarkListItem
