/** FAQ Card Content
 *  Component to return different components specific to module types
 *  for the FAQ card
 */
import type { ITitleAndCopyData, IVideoData } from '@unionco/alaris-app-types'

import { Video } from 'components/generic'

type TFAQContentModules = ITitleAndCopyData | IVideoData

interface IFAQCardContent {
  data: TFAQContentModules
}

export const FAQCardContent: React.FC<IFAQCardContent> = ({ data }) => {
  const { moduleType } = data
  switch (moduleType) {
    case 'video':
      return <Video className='u-mt-400' data={data} videoOnly />
    case 'titleAndCopy':
      return <p className='o-body-1 u-mt-200'>{data.copy}</p>
    default:
      return <></>
  }
}

export default IFAQCardContent
