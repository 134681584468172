import React from 'react'

import { TFreeResponseAnswer } from '@unionco/alaris-app-types'

interface IFreeResponseReviewProps {
  answered: boolean
  answers: TFreeResponseAnswer[]
}

export const FreeResponseReview: React.FC<IFreeResponseReviewProps> = ({
  answered,
  answers
}) => {
  const answerTextStyles = 'o-subtitle-2 u-font-bold'
  const defaultLabel = 'Please enter your response'
  const notAnsweredText = (
    <p className={`${answerTextStyles} o-body-1 u-text-state-danger`}>Not Answered</p>
  )
  if (!answered) return notAnsweredText
  return (
    <div className='c-flow'>
      {(answers as TFreeResponseAnswer[]).map((answer, index) => {
        if (!answer) return <></>
        return (
          <React.Fragment key={`FreeResponse_${index}`}>
            {answer.label !== defaultLabel && <p className='o-body-1'>{answer.label}</p>}
            {answer.value && answer.value !== '' ? (
              <p
                key={`selectedAnswer_${index}`}
                className={`${answerTextStyles} o-body-1 u-text-black`}
              >
                {answer.value}
              </p>
            ) : (
              notAnsweredText
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}
