import { cx } from '@unionco/utils'

interface IDivider {
  className?: string
  lineClasses?: string
  title: string
}

export const Divider: React.FC<IDivider> = ({
  className,
  lineClasses,
  title
}) => {
  return (
    <div className={cx('c-cluster print:u-m-1', className)}>
      <div className={cx('o-line u-grow', lineClasses)} />
      <p className='o-body-1 o-overline u-text-grayscale-500'>{title}</p>
      <div className={cx('o-line u-grow', lineClasses)} />
    </div>
  )
}

export const DividerDark: React.FC<IDivider> = ({
  className,
  lineClasses,
  title
}) => {
  return (
    <div className={cx('c-cluster print:u-m-1', className)}>
      <div className={cx('o-line u-grow', lineClasses)} style={{ backgroundColor: '#969696' }} />
      <p className='o-body-1 o-overline text-gray-900' style={{ fontSize: '1rem' }}>{title}</p>
      <div className={cx('o-line u-grow', lineClasses)} style={{ backgroundColor: '#969696' }} />
    </div>
  )
}

export default Divider; DividerDark
