/** Segment Header
 * Layout component used within question and segment review components
 */
import { useContext } from 'react'

import { IAMContext, TIAMContext } from 'context'

import { StyledMarkDown } from 'components'

interface ISegmentHeaderProps {
  eyebrow: string
  header: string
  indicator?: React.ReactNode | null
  subtext?: string
  supportText: string
  supportTextClasses?: string
  tiedQuestionText?: string
}

export const SegmentHeader: React.FC<ISegmentHeaderProps> = ({
  eyebrow,
  header,
  indicator,
  subtext,
  supportText,
  supportTextClasses = '',
  tiedQuestionText
}) => {
  const {
    IAMData: { impersonationMode }
  } = useContext(IAMContext) as TIAMContext
  return (
    <div className='c-flow | u-text-center'>
      <p className='o-body-1 o-overline'>{eyebrow}</p>
      {!!indicator && indicator}
      <p className='o-body-1 o-h2 | u-text-grayscale-800'>{header}</p>
      {subtext && (
        <StyledMarkDown content={subtext} paragraphTextAlign='center' />
      )}
      {tiedQuestionText && impersonationMode === 'impersonation' && (
        <StyledMarkDown content={tiedQuestionText} />
      )}
      <p className={`o-body-1 ${supportTextClasses}`}>{supportText}</p>
    </div>
  )
}

export default SegmentHeader
