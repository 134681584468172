/** Buyer Top Match Block
 *
 */
import { IMatchData, ITopMatchesCardData, ITopPrioritiesData } from '@unionco/alaris-app-types'

import { ModuleCard } from 'components/layout'

import TopMatchCard from './Card'

import { TopPriorities } from 'components'

interface ITopMatchesProps {
  content: ITopMatchesCardData
  matches: IMatchData[]
  data: ITopPrioritiesData
  canConfigureMatches: boolean
  isTopThree?: boolean
}

export const TopMatchesBlock: React.FC<ITopMatchesProps> = ({
  canConfigureMatches,
  content: { copy, title },
  data,
  matches,
  isTopThree
}) => {
  return (
    <ModuleCard.Body
      className='u-py-700'
      flowspace='u-flow-space-inner--700'
    >
      <div className='c-flow | u-flow-space-inner--400 | u-max-w-[34rem] u-mx-auto u-text-center'>
        {canConfigureMatches ? (<><h2 className='o-h2'>Configure Matches – Admin View</h2></>) : (<><h2 className='o-h2'>{title}</h2><p className='o-body-1'>{copy}</p></>)}
      </div>
      <div className='u-rounded'>
        <TopPriorities className={'u-w-full u-flex u-justify-between'} data={data} title={canConfigureMatches ? 'Seller\'s Top Priorities' : 'Your Top Priorities'} titleClassName={'u-flex u-justify-center'} bgClassName='u-bg-primary-300' />
      </div>
      {!canConfigureMatches && (
        <div className='c-switcher | [--switcher-gutter:2rem] [--switcher-threshold:50rem]'>
          {matches.map((match, i) => {
            return <TopMatchCard data={match} isTopThree={isTopThree} index={i} key={`TopMatch_${i}`} />
          })}
        </div>
      )}
    </ModuleCard.Body>
  )
}

export default TopMatchesBlock
