import { Accordion, AccordionContext } from '@unionco/components'
import { CaretIcon } from '@unionco/svg-icons'
import { useContext, useState } from 'react'

import type { IMatchConfiguration, IMatchData, TPercent } from '@unionco/alaris-app-types'

import { UserRoles } from '@unionco/alaris-utils'
import { cx, formatCurrencyByFold } from '@unionco/utils'
import { numberCountDisplayText } from 'utils'
import { isAdminTracking } from 'utils/tracking'
import {
  exploreBuyerMatchTracking,
  exploreSellerMatchTracking
} from 'utils/tracking/exploreMatch'

import { IAMContext, TClientContext, TIAMContext, UserContext } from 'context'

import { BusinessHeader } from 'components/BusinessHeader'
import { Img, StyledButton } from 'components/generic'
import { Divider } from 'components/modules'

import ConfigureMatchForm from '../ConfigureMatchForm'
import { HeatMapGrid } from '../HeatMapGrid'
import NegotiationPairs from '../NegotiationPairs'

import { API_BASE } from '@appConstants'
import axios from 'axios'

interface IMatchAccordionItemProps {
  buttonContent?: 'match' | 'revenueAndLocation';
  companyType?: 'buyer' | 'seller';
  data: IMatchData;
  drawerContent?: 'alignments' | 'buyer';
  index: number;
  matchCountAdjustment: number;
  useCounter?: boolean;
}

const MatchAccordionItem: React.FC<IMatchAccordionItemProps> = ({
  buttonContent = 'match',
  companyType,
  data: {
    adjacentMatches,
    alignments,
    companyId,
    companyLogo,
    companyName,
    companyType: matchCompanyType,
    configuration,
    description,
    location,
    matchConfidence,
    matchConfidenceLabel,
    revenue,
    websiteLink,
    websiteName
  },
  drawerContent,
  index,
  matchCountAdjustment,
  useCounter = true,
}) => {
  const { openItems } = useContext(AccordionContext);
  const matchColor =
    openItems && openItems.includes(index) ? 'u-text-primary-500' : '';
  const matchConfidenceString: TPercent = `${matchConfidence}%`;
  const { jwt, userType } = useContext(UserContext) as TClientContext;
  const { IAMData: { companyId: iamCompanyId, impersonationMode } } = useContext(IAMContext) as TIAMContext;
  const [clickedTitle, setClickedTitle] = useState<string>('General Alignment'); // State variable to store clicked title

  const handleSubmit = (
    configuration: IMatchConfiguration) => {
    setCompatibility(configuration);
  };

  const getButtonContent = () => {
    if (buttonContent === 'revenueAndLocation') {
      return (
        <>
          <p className='o-body-1'>Revenue: {formatCurrencyByFold(revenue)}</p>
          <p className='o-body-1'>{location}</p>
        </>
      )
    }
    return (
      <>
        {configuration?.compatible || configuration?.compatible === undefined
          ? <p className={cx('o-body-1 u-font-open-sans', matchColor)}>Match Confidence: {matchConfidenceLabel}</p>
          : <p className={cx('o-body-1 u-font-open-sans', matchColor)}>Incompatible: {configuration.compatibleReason}</p>
        }
      </>
    )
  }

  const href =
    companyType === UserRoles.SELLER
      ? `/cim?companyId=${companyId}`
      : `/buyer-at-a-glance?companyId=${companyId}`

  const isAdmin = UserRoles.ADMIN ? true : false;


  function trackingOnClick(matchPosition: number, matchScore: TPercent) {
    const exploreTrackingData = {
      company_name: companyName,
      aligned_most: alignments[0],
      top_matches_position: `${matchPosition}` as `${number}`,
      is_admin_key: isAdminTracking(userType)
    }
    if (matchCompanyType === 'buyer') {
      exploreBuyerMatchTracking({
        ...exploreTrackingData,
        aligned_least: alignments[1],
        company_type: 'buyer',
        confidence_match_score: matchScore
      })
    } else if (matchCompanyType === 'seller') {
      exploreSellerMatchTracking({
        ...exploreTrackingData,
        company_name: companyName,
        company_type: 'seller',
        also_matched_with: adjacentMatches,
        company_revenue: revenue
      })
    }
  }

  async function setCompatibility(configuration: IMatchConfiguration) {
    try {
      const matchResult = await axios.get(`${API_BASE}/api/sellers/${iamCompanyId}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      })

      const { topMatches } = matchResult.data;

      const matchIndex = topMatches.matches.findIndex((match: { companyId: number }) => match.companyId === companyId);

      if (matchIndex !== -1) {
        topMatches.matches[matchIndex].configuration = configuration;

        // // Clear out the tiltReason and compatibleReason if the tilt or compatible doesnt require a reason
        if (configuration.compatible) {
          topMatches.matches[matchIndex].compatibleReason = '';
        }

        configuration.tiltConfiguration.forEach((tiltConfig) => {
          if (tiltConfig.tilt === 0) {
            tiltConfig.tiltReason = '';
          }
        }
        );

        const updateData = {
          topMatches: topMatches
        };

        // Call the API to update the seller with the new 'topMatches' data
        await axios.put(`${API_BASE}/api/sellers/${iamCompanyId}/top-matches`, updateData, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });

        // Update matches for the seller
        await axios.post(`${API_BASE}/api/updateMatches?companyId=${iamCompanyId}&companyType=seller`, {}, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          }
        });

        // Reload the page
        window.location.reload();

      }

    } catch (error) {
      console.error(error)
    }
  }

  /**
   * Match Postion here is increased by 4
   * because the first three matches aren't
   * included in the accordion.
   *
   * So 1 to turn index into regular number,
   * plus 3 for the unincluded matches
   */
  const matchPosition = index + matchCountAdjustment + 1

  const handleHeatmapClick = (title: string) => {
    setClickedTitle(title); // Set the clicked title in state
  };

  return (
    <Accordion.Item
      index={index}
      className='u-bg-primary-300'
      key={`Match_${index}`}
    >
      <Accordion.Button
        index={index}
        locked={false}
        className='c-dep-repel u-p-400'
      >
        <div className='c-cluster'>
          {useCounter && (
            <h3 className='o-h3 u-text-4'>
              {numberCountDisplayText(matchPosition)}
            </h3>
          )}
          <div className='u-w-12'>
            <Img
              src={companyLogo}
              alt=''
              width={150}
              height={150}
              className='u-w-full'
            />
          </div>
          <h4 className='o-h4'>{companyName}</h4>
        </div>
        <div className='c-cluster'>
          {getButtonContent()}
          <Accordion.Indicator index={index} animation='upToDown'>
            <CaretIcon className='u-text-grayscale-500 [--icon-size:.75rem]' />
          </Accordion.Indicator>
        </div>
      </Accordion.Button>
      <Accordion.Drawer index={index}>
        <div className='u-px-400 u-pb-600 '>
          <div className="c-flow u-flex">
            <div className=''>
              {impersonationMode !== 'adminControl' && (
                <div className='c-flow u-flex u-w-full'>
                  <a className='o-body-1' href={websiteLink}>{websiteName}</a>
                  <p className='o-body-1'>{description}</p>
                  <StyledButton
                    type='a'
                    href={href}
                    theme='primary'
                    themeStyle='outline'
                    className='u-flex u-w-full u-max-w-[13.125rem] u-justify-center u-text-center'
                    onClick={() =>
                      trackingOnClick(matchPosition, matchConfidenceString)
                    }
                  >
                    Explore Match
                  </StyledButton>
                </div>
              )}

              {drawerContent === 'buyer' && (
                <div className='c-flow u-w-full'>
                  <Divider
                    title='Also Matched With'
                    lineClasses='[--line-color:theme(colors.secondary.300)]'
                  />
                  <div className='c-flow'>
                    {adjacentMatches.map(
                      ({ companyLogo, companyName }, index) => {
                        return (
                          <BusinessHeader
                            logo={companyLogo}
                            name={companyName}
                            key={`AdjacentMatch${index}`}
                          />
                        )
                      }
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className='u-w-full'>
              <NegotiationPairs
                title={clickedTitle}
                alignments={alignments}
              />
            </div>
          </div>
          <HeatMapGrid
            compatible={configuration.compatible}
            title={clickedTitle}
            alignment={alignments[0]}
            onElementClick={handleHeatmapClick}
          />
        </div>

        {impersonationMode === 'adminControl' && (
          <div className="u-p-4">
            <ConfigureMatchForm
              isAdmin={isAdmin}
              title={clickedTitle}
              configuration={configuration}
              handleSubmit={handleSubmit}
            />
          </div>
        )}
      </Accordion.Drawer>
    </Accordion.Item>
  )
}

export default MatchAccordionItem;