
import { ModuleCard } from 'components/layout'
import { cx } from '@unionco/utils'
import { IMatchData } from '@unionco/alaris-app-types'
import { Img } from 'components/generic'

import tw from 'twin.macro'

interface IOtherMatchesProps {
  title: string
  matches: IMatchData[]
}


export const OtherMatchesBlock: React.FC<IOtherMatchesProps> = ({
  matches,
  title,

}) => {

  return (

    <ModuleCard.Body
      className=' u-w-full u-overflow-hidden u-rounded u-p-500'
      padding={null}
      flowspace='u-flow-space-inner--500'
    >
      <ModuleCard.Header title={title}></ModuleCard.Header>
      {matches.map((match, i) => {
        return (
          <div key={i} className='u-flex u-content-center c-flow'>
            <ModuleCard.Body
              className='u-p-400 u-overflow-hidden u-rounded u-w-full'
              bgColorClass={tw`u-bg-primary-300`}
              padding={null}
              flowspace='u-flow-space-inner--500'
            >
              <div className='u-flex u-justify-between'>
                <div className='u-flex c-cluster u-items-center'>
                  <div className='u-w-12 }'>
                    <Img
                      src={match.companyLogo}
                      alt=''
                      width={150}
                      height={150}
                      className='u-w-full'
                    />
                  </div>
                  <h4 className='o-h4'>{match.companyName}</h4>
                </div>
                <div className='u-my-auto'>
                  {title === 'Incompatible Matches' ?
                    <p className={cx('o-body-1 u-font-open-sans')}>Reason: {match?.configuration?.compatibleReason}</p>
                    : <p className={cx('o-body-1 u-font-open-sans')}>Reason: Low Confidence</p>
                  }
                </div>
              </div>
            </ModuleCard.Body>
          </div>)
      })}
    </ModuleCard.Body>

  )
}

export default OtherMatchesBlock;