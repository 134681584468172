/** Password Reset Modal
 *  Modal for restting password,
 *  TODO: Logic for sending email to users account
 */
import { EmailInput } from '@unionco/components'
import Link from 'next/link'
import { FormEvent, useState } from 'react'

import { StyledButton } from 'components'

import AuthCard from '../AuthCard'
import PasswordResetMessage from './ResetMessageCard'

export const ResetPasswordCard = () => {
  const [emailSent, setEmailSent] = useState(false)
  const [emailInputValue, setEmailInputValue] = useState<string | null>(null)
  const [emailErrorMessage, setEmailErrorMessage] = useState<
    string | undefined
  >(undefined)
  const emailInputId = 'resetEmail'

  const sendEmail = (email: string) => {
    setEmailSent(true)
    setEmailInputValue(email)
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!e || !e.target) return

    const target = e.target as HTMLFormElement
    if (target[emailInputId].value === '') {
      setEmailErrorMessage('Please enter a valid email.')
      return
    }

    sendEmail(target.resetEmail.value)
  }

  const resetPasswordContent = (emailSent: boolean) => {
    switch (emailSent) {
      case true:
        return (
          emailInputValue && (
            <PasswordResetMessage
              email={emailInputValue}
              callBack={() => sendEmail(emailInputValue)}
            />
          )
        )
      default:
        return (
          <>
            <div className='c-flow | u-text-center'>
              <h2 className='o-h2'>Reset Your Password</h2>
              <p className='o-body-1'>Please enter your email below</p>
            </div>
            <form
              className='c-flow | u-flow-space--400 u-mt-500'
              onSubmit={onSubmit}
            >
              <EmailInput id={emailInputId} errorMessage={emailErrorMessage} />
              <StyledButton type='submit'>Reset Password</StyledButton>
            </form>
            <Link href='/login'>Back to login</Link>
          </>
        )
    }
  }

  return (
    <AuthCard
      id='b-ResetPasswordCard'
      className='u-w-full u-max-w-[29.5rem] u-justify-center u-text-center'
    >
      {resetPasswordContent(emailSent)}
    </AuthCard>
  )
}

export default ResetPasswordCard
