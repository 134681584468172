import { TImageProps } from '@unionco/alaris-app-types'

import { Img } from 'components/generic'

interface ISearchResult {
  content: string
  img: TImageProps
  onClick: (...args: unknown[]) => void
}

export const SearchResult: React.FC<ISearchResult> = ({
  content,
  img,
  onClick
}) => {
  return (
    <button
      className='c-dep-repel | u-group u-w-full u-bg-primary-300 u-px-600 u-py-400 hover:u-bg-primary-500'
      onClick={onClick}
    >
      <p className='o-body-1 group-hover:u-text-white'>{content}</p>
      {img.src && <Img className='u-max-w-[2rem]' {...img} />}
    </button>
  )
}

export default SearchResult
