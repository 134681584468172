/**
 * Notes Panel
 *
 * Used within the questionnaire
 */
import { useContext, useEffect, useRef, useState } from 'react'

import {
  IQuestionSegmentData,
  IQuestionnaireData
} from '@unionco/alaris-app-types'

import { cx } from '@unionco/utils'
import { getCurrentQuestionData } from 'components/questionnaire/util'
import { getCurrentSegmentData } from 'utils'
import { isAdminTracking, notesTracking } from 'utils/tracking'

import { IAMContext, TIAMContext, TUserContext, UserContext } from 'context'

import { SidebarContext } from 'components/SidebarTemplate'
import { StyledButton } from 'components/generic'
import { QuestionContext } from 'components/questionnaire/question/context'

interface INotesPanelData {
  notes: string | null
}

interface INotesPanel {
  data: INotesPanelData
}

export const NotesPanel: React.FC<INotesPanel> = ({ data: { notes } }) => {
  const { userType } = useContext(UserContext) as TUserContext
  const {
    IAMData: { companyName }
  } = useContext(IAMContext) as TIAMContext
  const { data: questionniareData, pageLocations } = useContext(SidebarContext)
  const { submitAnswer } = useContext(QuestionContext)
  // Local State
  const [open, setOpen] = useState<boolean>(false)
  const notesRef = useRef<HTMLTextAreaElement>(null)

  const submitNotes = () => {
    if (!notesRef) return
    // Get input
    const { current: notesInput } = notesRef
    // Get context location of question
    const newState = { ...questionniareData } as IQuestionnaireData
    const currentSegment = getCurrentSegmentData(
      pageLocations,
      newState
    ) as IQuestionSegmentData
    const currentQuestion = getCurrentQuestionData(pageLocations, newState)
    // Update local state
    if (currentQuestion && notesInput) {
      currentQuestion.adminNotes = notesInput.value
      currentSegment.questions[pageLocations['question'] as number] =
        currentQuestion
    }
    newState.segments[pageLocations['segment']] = currentSegment

    // Submit
    submitAnswer(null, newState, pageLocations)
    notesTracking({
      company_name: companyName,
      is_admin_key: isAdminTracking(userType)
    })
  }

  // If a change is made to notes, update notesRef
  useEffect(() => {
    if (notesRef.current) {
      notesRef.current.value = notes || '';
    }
  }, [notes]);

  const stateClass = open ? 'u-translate-x-[-100%]' : null

  return (
    <div className='c-layer'>
      <div className='u-fixed u-bottom-[2rem] u-right-[2rem]'>
        <StyledButton
          type='button'
          theme='primary'
          themeStyle='solid'
          onClick={() => setOpen(true)}
        >
          Notes
        </StyledButton>
      </div>
      <div
        className={cx(
          'u-w-max-full u-fixed u-left-full u-h-full u-w-full u-bg-white u-p-400 u-pt-700 u-transition-transform lg:u-max-w-[24rem]',
          stateClass
        )}
      >
        <div className='c-flow u-h-full'>
          <h3 className='o-h3'>Notes:</h3>
          <textarea
            className='u-w-full u-flex-grow u-bg-primary-300 u-px-6 u-py-4'
            defaultValue={notes || ''}
            ref={notesRef}
          />
          <div className='c-dep-repel'>
            <StyledButton
              type='button'
              theme='primary'
              themeStyle='solid'
              className='u-flex-grow u-tracking-wide'
              onClick={submitNotes}
            >
              Save Entry
            </StyledButton>
            <StyledButton
              type='button'
              theme='primary'
              themeStyle='outline'
              className='u-flex-grow u-tracking-wide'
              onClick={() => setOpen(false)}
            >
              Close
            </StyledButton>
          </div>
        </div>
      </div>
    </div>
  )
}
