/** Icon Header
 *  Displays an icon next to stacked text
 *  Can be an internal link
 *
 *  Appears inside of dashboard header
 *  as well as within the questionniare
 */
import Link from 'next/link'

import { TIconName } from '@unionco/alaris-app-types'

import { Icon } from '../svg'

export interface IIconHeaderData {
  eyebrow: string
  dark?: boolean
  header: string
  icon?: TIconName
}

export type TIconHeaderProps = IIconHeaderData &
  (
    | {
      href?: undefined
      isLink: false
    }
    | {
      href: string
      isLink: true
    }
  )

export const IconHeader: React.FC<TIconHeaderProps> = ({
  dark,
  eyebrow,
  header,
  href,
  icon = 'dashbaord' as TIconName,
  isLink
}) => {
  const layoutClasses = 'c-cluster'
  const eyebrowColor = dark ? ' u-text-white' : ''
  const headerColor = dark ? ' u-text-white' : 'u-text-black'

  const content = (
    <>
      <div className='u-flex u-h-11 u-w-11 u-items-center u-justify-center u-rounded-full u-bg-black u-text-white'>
        <Icon name={icon} />
      </div>
      <div>
        <p className={`o-body-1 o-overline ${eyebrowColor}`}>{eyebrow}</p>
        <h2 className={`o-h2 ${headerColor}`}>{header}</h2>
      </div>
    </>
  )

  if (isLink && href) {
    return (
      <Link href={href} className={layoutClasses}>
        {content}
      </Link>
    )
  }

  return <div className={layoutClasses}>{content}</div>
}

export default IconHeader
