import { Accordion, Card, Input, Tooltip } from '@unionco/components'
import { useEffect, useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import tw from 'twin.macro'

import { IInfoData, IInputLabelValue, IOwnerFormData } from '@unionco/alaris-app-types'

import { fieldIsValid } from './utils'
import { cx } from '@unionco/utils'

import { useNavigationClickTracking } from 'hooks/useNavigationClickTracking'

import EntryControls from './EntryControls'
import EntryTitle from './EntryTitle'
import {
  BaseSalaryInput,
  Dropdown,
  NameInputs,
  NumericFieldInput,
  OwnershipInput,
  ProfilePic,
  YesNoSelect
} from './inputs'
import LabelAndInput from './labelAndInput'
import { inputStyling } from './mod'

interface IOwnerFormProps {
  data: IOwnerFormData
  entriesTitle: string
  index: number
  info: IInfoData
  deleteEntry: (i: number) => void
  // Passes in the index of the form entry and the custom transactionGoal array of objects
  saveEntry: (i: number, transactionGoals: any) => Promise<null | true | string[]>
  saved: boolean
}

// Placeholder values for the transaction goals dropdown
// Should be replaced with values from Strapi API long-term
const transactionGoalsOptions: IInputLabelValue[] = [
  {
    value: 'Liquidity',
    label: 'Liquidity'
  },
  {
    value: 'Succession',
    label: 'Succession'
  },
  {
    value: 'Change How I Work',
    label: 'Change How I Work'
  },
  {
    value: 'Develop/Opportunities For My Staff',
    label: 'Develop/Opportunities For My Staff'
  },
  {
    value: 'Accelerate Our Growth',
    label: 'Accelerate Our Growth'
  },
  {
    value: 'Participate in Equity Growth',
    label: 'Participate in Equity Growth'
  },
  {
    value: 'Career Change',
    label: 'Career Change'
  },
]

/**
 * OwnerForm component
 *
 * @returns Question UI where a user can add individual forms per owner
 * that exists within their company. They can also individually save,
 * and delete each entry.
 */
export const OwnerForm: React.FC<IOwnerFormProps> = ({
  data: {
    additionalNotes,
    age,
    aumManaged,
    baseSalary,
    firstName,
    glidepath,
    hhManaged,
    involvedInBusiness,
    jobDescription,
    lastName,
    ownership,
    profilePic,
    revenueManaged,
    title,
    transactionGoal
  },
  deleteEntry,
  entriesTitle,
  index: i,
  info,
  saveEntry,
  saved
}) => {
  const { navTrackingOnClick } = useNavigationClickTracking()
  const [validation, setValidation] = useState<null | true | string[]>(null)
  const [entryTitle, setEntryTitle] = useState<string>(entriesTitle)
  const [headerJobTitle, setHeaderJobTitle] = useState<string | null>(null)
  const [selectedGoals, setSelectedGoals] = useState(transactionGoal || []);

  // Handles the transaction goals dropdown
  const handleChange = (selectedOptions: any) => {
    setSelectedGoals(selectedOptions);
  };

  /**
   * Entry title name use effect
   * Sets entry title to name of
   */
  useEffect(() => {
    if ((firstName && firstName.value) || (lastName && lastName.value)) {
      const name = cx(firstName.value, lastName.value)
      setEntryTitle(name)
    }
  }, [firstName, lastName, setEntryTitle])

  /**
   * Entry title - Job title subtext
   * Sets entry title
   */
  useEffect(() => {
    if (title && title.value && title.value !== '')
      setHeaderJobTitle(title.value)
  }, [title, setHeaderJobTitle])

  const updateValidation = async () => {
    const result = await saveEntry(i, selectedGoals)
    setValidation(result)
  }

  return (
    <Card className='b-Entry u-p-500'>
      <Accordion.Item index={i}>
        <EntryTitle
          entryTitle={entryTitle}
          headerJobTitle={headerJobTitle}
          index={i}
          saved={saved}
        />
        <Accordion.Drawer index={i} className='c-flow u-flow-space--500'>
          <div className='c-cluster u-mt-500'>
            <div className='c-flow u-basis-[calc(75%-1rem)]'>
              <div className='u-flex u-justify-between'>
                <NameInputs
                  firstName={firstName}
                  lastName={lastName}
                  validation={validation}
                />
                <LabelAndInput
                  label='Title'
                  required
                  className='u-basis-[calc(33%-.5rem)]'
                >
                  <Input
                    id=''
                    className={cx(
                      ' u-w-full',
                      fieldIsValid('title', validation)
                    )}
                    classNames={inputStyling}
                    placeholder='CEO'
                    defaultValue={title ? title.value : ''}
                    name='title'
                    required
                  />
                </LabelAndInput>
              </div>
              <div className='u-flex u-justify-between'>
                <OwnershipInput
                  defaultValue={ownership.value}
                  validation={validation}
                />
                <NumericFieldInput
                  defaultValue={age ? age.value : ''}
                  placeholder='65'
                  label='Age'
                  name='age'
                  validation={validation}
                  required={true}
                />
                <BaseSalaryInput
                  defaultValue={baseSalary.value}
                  validation={validation}
                  required={true}
                />
              </div>
            </div>
            <ProfilePic
              profilePic={profilePic}
              className='u-basis-[calc(25%-1rem)]'
            />
          </div>
          <div className='c-switcher u-w-full'>
            <LabelAndInput
              label='What would your primary transaction goals be?'
              required
              className='u-flex-grow'
            >
              <CreatableSelect
                id=''
                className={cx(
                  ' u-w-full',
                  fieldIsValid('transactionGoal', validation),
                  'u-rounded u-p-400 u-bg-primary-300'
                )}
                //classNames={inputStyling}
                isMulti
                options={transactionGoalsOptions}
                onChange={handleChange}
                value={selectedGoals}
                placeholder='Type your own or select from list'
                name='transactionGoal'
                required
                isClearable
              />
            </LabelAndInput>
            <LabelAndInput label='Glidepath' required className='u-flex-grow'>
              <Input
                id=''
                className={cx(
                  ' u-w-full',
                  fieldIsValid('glidepath', validation)
                )}
                classNames={inputStyling}
                placeholder='At 10 years from retirement (age 55)'
                defaultValue={glidepath ? glidepath.value : ''}
                name='glidepath'
                required
              />
            </LabelAndInput>
          </div>
          <div className='c-switcher u-w-full'>
            <NumericFieldInput
              defaultValue={revenueManaged ? revenueManaged.value : ''}
              placeholder='20%'
              label={`Percent of firm's revenue managed`}
              name='revenueManaged'
              validation={validation}
              required={true}
            />
            <NumericFieldInput
              defaultValue={aumManaged ? aumManaged.value : ''}
              placeholder='20%'
              label={`Percent of firm's AUM managed`}
              name='aumManaged'
              validation={validation}
              required={true}
            />
          </div>
          <div className='c-switcher u-w-full'>
            <YesNoSelect
              defaultValue={involvedInBusiness ? involvedInBusiness.value : ''}
              validation={validation}
              label={`Involved in business?`}
              name='involvedInBusiness'
            />
          </div>
          <div className='c-switcher u-w-full'>
            <NumericFieldInput
              defaultValue={hhManaged ? hhManaged.value : ''}
              placeholder='20'
              label={`Percentage of households managed`}
              name='hhManaged'
              validation={validation}
              required={true}
            />
          </div>
          <div className='u-basis-[calc(50%-.5rem)]'>
            <LabelAndInput label='Role in the Firm' required>
              <textarea
                className='u-min-h-[5rem] u-w-full u-rounded u-border-2 u-border-secondary-400 u-p-400 u-text-secondary-800'
                defaultValue={jobDescription ? jobDescription.value : ''}
                name='jobDescription'
              />
            </LabelAndInput>
          </div>
          <LabelAndInput
            label='Additional Notes'
            headerContent={
              <div className='c-cluster | [--cluster-gutter:.5rem]'>
                <p className='o-body-1 u-font-open-sans u-text-2'>More Info</p>
                <Tooltip
                  copy={info?.copy || ''}
                  iconColorClass={tw`u-text-primary-500`}
                  revealCallback={() =>
                    navTrackingOnClick(
                      'info tooltip',
                      'owner form question additional notes'
                    )
                  }
                />
              </div>
            }
          >
            <textarea
              className='u-min-h-[5rem] u-w-full u-rounded u-border-2 u-border-secondary-400 u-p-400 u-text-secondary-800'
              defaultValue={additionalNotes.value}
              name='additionalNotes'
            />
          </LabelAndInput>
          <EntryControls
            index={i}
            deleteEntry={deleteEntry}
            saveEntry={updateValidation}
          />
        </Accordion.Drawer>
      </Accordion.Item>
    </Card>
  )
}

export default OwnerForm
