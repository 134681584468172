/**
 * Displays company logo and name
 */
import type { FC } from 'react'

import { Img } from 'components/generic'

export interface IBusinessHeaderProps {
  logo: string
  name: string
}

export const BusinessHeader: FC<IBusinessHeaderProps> = ({ logo, name }) => {
  return (
    <div className='c-cluster'>
      <div className='u-w-12'>
        <Img src={logo} alt='' width={150} height={150} className='u-w-full' />
      </div>
      <h4 className='o-h4'>{name}</h4>
    </div>
  )
}
