/** Caret Icon
 *
 */
import { TDirection, TSVGProps } from '@unionco/alaris-app-types'

import { TwStyle } from 'twin.macro'

interface IChevronIconProps extends TSVGProps {
    iconDirection?: TDirection
    cssClasses?: TwStyle
}

const ChevronIconDown: React.FC<TSVGProps & { path: string; }> = ({
    className,
    path,
    viewBox,
    ...props
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path d="M3.49818 12.2764L8.45775 7.53243L13.1864 12.2611L14.5772 10.8703L8.48831 4.78149L2.13867 10.8551L3.49818 12.2764Z" fill="black" />
        </svg>
    )
}

export const ChevronIcon: React.FC<IChevronIconProps> = ({
    iconDirection = 'down',
    ...props
}) => {
    const path = 'M3.49818 12.2764L8.45775 7.53243L13.1864 12.2611L14.5772 10.8703L8.48831 4.78149L2.13867 10.8551L3.49818 12.2764Z'

    switch (iconDirection) {
        case 'right':
            return (
                <ChevronIconDown
                    path={path}
                    style={{ transform: 'rotate(-90deg)' }}
                    {...props}
                />
            )
        case 'down':
            return <ChevronIconDown path={path} {...props} />
        case 'left':
            return (
                <ChevronIconDown
                    path={path}
                    style={{ transform: 'rotate(90deg)' }}
                    {...props}
                />
            )
        case 'up':
            return (
                <ChevronIconDown
                    path={path}
                    style={{ transform: 'rotate(180deg)' }}
                    {...props}
                />
            )
        default:
            return <></>
    }
}

export default ChevronIcon
