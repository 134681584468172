import { Accordion } from '@unionco/components'

import { ITeamMembersModuleData } from '@unionco/alaris-app-types'
import BulletIcon from 'components/svg/icons/bullet/Bullet'
import ChevronIcon from 'components/svg/icons/chevron'

export interface ITeamMembersModuleProps {
  data: ITeamMembersModuleData
}

/**
 * TeamMembersModule - module component
 *
 * @returns UI for displaying people involved with the business
 * data comes from form question types within the questionnaire
 */
export const TeamMembersModule: React.FC<ITeamMembersModuleProps> = ({
  data: {
    category1,
    category2,
    category3,
    departmentHeads,
    employees,
    founders,
    info,
    leaders,
    nonOwners,
    title,
    advisoryAUMByAdvisor
  }
}) => {
  const employeePresent = employees && employees.length > 0
  const nonOwnerPresent = nonOwners && nonOwners.length > 0
  const founderPresent = founders && founders.length > 0
  const leaderPresent = leaders && leaders.length > 0
  const departmentHeadPresent = departmentHeads && departmentHeads.length > 0
  const advisoryAUMByAdvisorPresent =
    advisoryAUMByAdvisor && advisoryAUMByAdvisor.advisoryAUM.length > 0

  if (
    !departmentHeadPresent &&
    !leaderPresent &&
    !employeePresent &&
    !nonOwnerPresent &&
    !founderPresent &&
    !advisoryAUMByAdvisorPresent
  )
    return <></>

  return (
    <div>
      {/* SELLER TEAM MEMBERS */}
      {founderPresent && (
        <div className='u-pb-11'>
          <h5 className='u-pb-7'>Founders</h5>
          <Accordion.Main
            className='c-accordion'
            multipleOpen
            initialOpenItems={[0]}
          >
            {founders &&
              founders.map((m, index) => {
                return (
                  <Accordion.Item
                    index={index}
                    key={`Match_${index}`}
                    className='c-accordion-item'
                  >
                    <Accordion.Button
                      index={index}
                      locked={false}
                      className='c-accordion-button'
                    >
                      <div className='u-flex'>
                        {m.profilePic && (
                          <img src={m.profilePic.url} alt={m.firstName} />
                        )}
                        <div className='u-justify-items-start u-self-center u-pl-7'>
                          <h6 className='u-pb-2'>
                            {m.firstName} {m.lastName}
                          </h6>
                          <p className='lens-overline-text'>
                            {m.title}
                          </p>
                        </div>
                      </div>

                      <Accordion.Indicator index={index} animation='upToDown'>
                        <div className='u-flex u-w-[48px] u-h-[48px] u-rounded-full u-bg-[#FFF] u-items-center u-justify-center'>
                          <ChevronIcon></ChevronIcon>
                        </div>
                      </Accordion.Indicator>
                    </Accordion.Button>
                    <Accordion.Drawer
                      index={index}
                      className='c-accordion-drawer'
                    >
                      <div className='c-accordion-drawer-contents'>
                        {m.jobDescription && (
                          <div>
                            <p className='lens-overline-text'>ROLE</p>
                            <div className='u-flex u-flex-row u-gap-4'>
                              <BulletIcon></BulletIcon>
                              <p>{m.jobDescription}</p>
                            </div>
                          </div>
                        )}
                        {m.ownership && (
                          <div>
                            <p className='lens-overline-text'>
                              OWNERSHIP PERCENTAGES
                            </p>
                            <div className='u-flex u-flex-row u-gap-4'>
                              <BulletIcon></BulletIcon>
                              <p>{m.ownership}</p>
                            </div>
                          </div>
                        )}
                        {m.age && (
                          <div>
                            <p className='lens-overline-text'>OWNER'S AGE</p>
                            <div className='u-flex u-flex-row u-gap-4'>
                              <BulletIcon></BulletIcon>
                              <p>{m.age}</p>
                            </div>
                          </div>
                        )}
                        {m.glidePath && (
                          <div>
                            <p className='lens-overline-text'>
                              ESTIMATE TIME TO RETIREMENT
                            </p>
                            <div className='u-flex u-flex-row u-gap-4'>
                              <BulletIcon></BulletIcon>
                              <p>{m.glidePath}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </Accordion.Drawer>
                  </Accordion.Item>
                )
              })}
          </Accordion.Main>
        </div>
      )}
      {nonOwnerPresent && (
        <div className='u-pb-11'>
          <h5 className='u-pb-7'>Non-Owners</h5>
          <Accordion.Main className='c-accordion' multipleOpen>
            {nonOwners &&
              nonOwners.map((m, index) => {
                return (
                  <Accordion.Item
                    index={index}
                    key={`Match_${index}`}
                    className='c-accordion-item'
                  >
                    <Accordion.Button
                      index={index}
                      locked={false}
                      className='c-accordion-button'
                    >
                      <div className='u-flex'>
                        {m.profilePic && (
                          <img src={m.profilePic.url} alt={m.firstName} />
                        )}
                        <div className='u-justify-items-start u-self-center u-pl-7'>
                          <h6 className='u-pb-2'>
                            {m.firstName} {m.lastName}
                          </h6>
                          <p className='lens-overline-text'>
                            {m.title}
                          </p>
                        </div>
                      </div>

                      <Accordion.Indicator index={index} animation='upToDown'>
                        <div className='u-flex u-w-[48px] u-h-[48px] u-rounded-full u-bg-[#FFF] u-items-center u-justify-center'>
                          <ChevronIcon></ChevronIcon>
                        </div>
                      </Accordion.Indicator>
                    </Accordion.Button>

                    <Accordion.Drawer
                      index={index}
                      className='c-accordion-drawer'
                    >
                      <div className='c-accordion-drawer-contents'>
                        {m.title && (
                          <div>
                            <p className='lens-overline-text'>TITLE</p>
                            <div className='u-flex u-flex-row u-gap-4'>
                              <BulletIcon></BulletIcon>
                              <p>{m.title}</p>
                            </div>
                          </div>
                        )}
                        {m.jobDescription && (
                          <div>
                            <p className='lens-overline-text'>
                              JOB DESCRIPTION
                            </p>
                            <div className='u-flex u-flex-row u-gap-4'>
                              <BulletIcon></BulletIcon>
                              <p>{m.jobDescription}</p>
                            </div>
                          </div>
                        )}
                        {m.baseSalary && (
                          <div>
                            <p className='lens-overline-text'>SALARY</p>
                            <div className='u-flex u-flex-row u-gap-4'>
                              <BulletIcon></BulletIcon>
                              <p>{m.baseSalary}</p>
                            </div>
                          </div>
                        )}
                        {m.age && (
                          <div>
                            <p className='lens-overline-text'>
                              NON-OWNER'S AGE
                            </p>
                            <div className='u-flex u-flex-row u-gap-4'>
                              <BulletIcon></BulletIcon>
                              <p>{m.age}</p>
                            </div>
                          </div>
                        )}
                        {m.aumManaged && (
                          <div>
                            <p className='lens-overline-text'>AUM MANAGED</p>
                            <div className='u-flex u-flex-row u-gap-4'>
                              <BulletIcon></BulletIcon>
                              <p>{m.aumManaged}</p>
                            </div>
                          </div>
                        )}
                        {m.revenueManaged && (
                          <div>
                            <p className='lens-overline-text'>
                              REVENUE MANAGED
                            </p>
                            <div className='u-flex u-flex-row u-gap-4'>
                              <BulletIcon></BulletIcon>
                              <p>{m.revenueManaged}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </Accordion.Drawer>
                  </Accordion.Item>
                )
              })}
          </Accordion.Main>
        </div>
      )}
      {employeePresent && (
        <div className='u-pb-11'>
          <h5 className='u-pb-7'>Employees</h5>
          <Accordion.Main className='c-accordion' multipleOpen>
            {employees &&
              employees.map((m, index) => {
                return (
                  <Accordion.Item
                    index={index}
                    key={`Match_${index}`}
                    className='c-accordion-item'
                  >
                    <Accordion.Button
                      index={index}
                      locked={false}
                      className='c-accordion-button'
                    >
                      <div className='u-flex'>
                        {m.profilePic && (
                          <img src={m.profilePic.url} alt={m.firstName} />
                        )}
                        <div className='u-justify-items-start u-self-center u-pl-7'>
                          <h6 className='u-pb-2'>
                            {m.firstName} {m.lastName}
                          </h6>
                          <p className='lens-overline-text'>{m.title}</p>
                        </div>
                      </div>

                      <Accordion.Indicator index={index} animation='upToDown'>
                        <div className='u-flex u-w-[48px] u-h-[48px] u-rounded-full u-bg-[#FFF] u-items-center u-justify-center'>
                          <ChevronIcon></ChevronIcon>
                        </div>
                      </Accordion.Indicator>
                    </Accordion.Button>

                    <Accordion.Drawer
                      index={index}
                      className='c-accordion-drawer'
                    >
                      <div className='c-accordion-drawer-contents'>
                        {m.title && (
                          <div>
                            <p className='lens-overline-text'>TITLE</p>
                            <div className='u-flex u-flex-row u-gap-4'>
                              <BulletIcon></BulletIcon>
                              <p>{m.title}</p>
                            </div>
                          </div>
                        )}
                        {m.jobDescription && (
                          <div>
                            <p className='lens-overline-text'>
                              JOB DESCRIPTION
                            </p>
                            <div className='u-flex u-flex-row u-gap-4'>
                              <BulletIcon></BulletIcon>
                              <p>{m.jobDescription}</p>
                            </div>
                          </div>
                        )}
                        {m.baseSalary && (
                          <div>
                            <p className='lens-overline-text'>SALARY</p>
                            <div className='u-flex u-flex-row u-gap-4'>
                              <BulletIcon></BulletIcon>
                              <p>{m.baseSalary}</p>
                            </div>
                          </div>
                        )}
                        {m.age && (
                          <div>
                            <p className='lens-overline-text'>EMPLOYEE'S AGE</p>
                            <div className='u-flex u-flex-row u-gap-4'>
                              <BulletIcon></BulletIcon>
                              <p>{m.age}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </Accordion.Drawer>
                  </Accordion.Item>
                )
              })}
          </Accordion.Main>
        </div>
      )}

      {/* BUYER TEAM MEMBERS */}
      {leaderPresent && (
        <div className='u-pb-11'>
          <h5 className='u-pb-7'>Leaders</h5>
          <Accordion.Main
            className='c-accordion'
            multipleOpen
            initialOpenItems={[0]}
          >
            {leaders &&
              leaders.map((m, index) => {
                return (
                  <Accordion.Item
                    index={index}
                    key={`Match_${index}`}
                    className='c-accordion-item'
                  >
                    <Accordion.Button
                      index={index}
                      locked={false}
                      className='c-accordion-button'
                    >
                      <div className='u-flex'>
                        {m.profilePic && (
                          <img src={m.profilePic.url} alt={m.firstName} />
                        )}
                        <div className='u-justify-items-start u-self-center u-pl-7'>
                          <h6 className='u-pb-2'>
                            {m.firstName} {m.lastName}
                          </h6>
                          <p className='lens-overline-text'>{m.title}</p>
                        </div>
                      </div>

                      <Accordion.Indicator index={index} animation='upToDown'>
                        <div className='u-flex u-w-[48px] u-h-[48px] u-rounded-full u-bg-[#FFF] u-items-center u-justify-center'>
                          <ChevronIcon></ChevronIcon>
                        </div>
                      </Accordion.Indicator>
                    </Accordion.Button>

                    <Accordion.Drawer
                      index={index}
                      className='c-accordion-drawer'
                    >
                      <div className='c-accordion-drawer-contents'>
                        {m.title && (
                          <div>
                            <p className='lens-overline-text'>TITLE</p>
                            <div className='u-flex u-flex-row u-gap-4'>
                              <BulletIcon></BulletIcon>
                              <p>{m.title}</p>
                            </div>
                          </div>
                        )}
                        {m.biography && (
                          <div>
                            <p className='lens-overline-text'>BIOGRAPHY</p>
                            <div className='u-flex u-flex-row u-gap-4'>
                              <BulletIcon></BulletIcon>
                              <p>{m.biography}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </Accordion.Drawer>
                  </Accordion.Item>
                )
              })}
          </Accordion.Main>
        </div>
      )}
      {departmentHeadPresent && (
        <div className='u-pb-11'>
          <h5 className='u-pb-7'>Department Heads</h5>
          <Accordion.Main
            className='c-accordion'
            multipleOpen
          >
            {departmentHeads &&
              departmentHeads.map((m, index) => {
                return (
                  <Accordion.Item
                    index={index}
                    key={`Match_${index}`}
                    className='c-accordion-item'
                  >
                    <Accordion.Button
                      index={index}
                      locked={false}
                      className='c-accordion-button'
                    >
                      <div className='u-flex'>
                        {m.profilePic && (
                          <img src={m.profilePic.url} alt={m.firstName} />
                        )}
                        <div className='u-justify-items-start u-self-center u-pl-7'>
                          <h6 className='u-pb-2'>
                            {m.firstName} {m.lastName}
                          </h6>
                          <p className='lens-overline-text'>{m.title}</p>
                        </div>
                      </div>

                      <Accordion.Indicator index={index} animation='upToDown'>
                        <div className='u-flex u-w-[48px] u-h-[48px] u-rounded-full u-bg-[#FFF] u-items-center u-justify-center'>
                          <ChevronIcon></ChevronIcon>
                        </div>
                      </Accordion.Indicator>
                    </Accordion.Button>

                    <Accordion.Drawer
                      index={index}
                      className='c-accordion-drawer'
                    >
                      <div className='c-accordion-drawer-contents'>
                        {m.title && (
                          <div>
                            <p className='lens-overline-text'>TITLE</p>
                            <div className='u-flex u-flex-row u-gap-4'>
                              <BulletIcon></BulletIcon>
                              <p>{m.title}</p>
                            </div>
                          </div>
                        )}
                        {m.jobDescription && (
                          <div>
                            <p className='lens-overline-text'>
                              JOB DESCRIPTION
                            </p>
                            <div className='u-flex u-flex-row u-gap-4'>
                              <BulletIcon></BulletIcon>
                              <p>{m.jobDescription}</p>
                            </div>
                          </div>
                        )}
                      </div>
                    </Accordion.Drawer>
                  </Accordion.Item>
                )
              })}
          </Accordion.Main>
        </div>
      )}
    </div>
  )
}

export default TeamMembersModule
