import { Card } from '@unionco/components'
import tw from 'twin.macro'

import { IBasicMetricData } from '@unionco/alaris-app-types'

import { cx } from '@unionco/utils'

interface IMetricCardClassNames {
  root: string
  title: string
  value: string
}

interface IMetricCardProps {
  className?: string
  classNames?: Partial<IMetricCardClassNames>
  data: IBasicMetricData
}

export const MetricCard: React.FC<IMetricCardProps> = ({
  className,
  classNames = {},
  data: { title, value }
}) => {
  return (
    <Card
      bgColorClass={tw`u-bg-primary-300`}
      className={cx(
        'c-flow | u-flow-space--200 | u-grow u-px-500 u-py-500 u-text-center print:u-p-300 print:break-after-avoid-page',
        className,
        classNames.root
      )}
    >
      <p className={cx('o-body-1 o-subtitle-2 u-text-secondary-800', classNames.title)}>
        {title}
      </p>
      <div className="u-mt-auto">
        <p
          className={cx('o-h3 u-font-bold u-text-primary-500', classNames.value)}
        >
          {value}
        </p>
      </div>
    </Card>
  )
}

export default MetricCard
