/** Module Card Body
 *  Card instance styled specifically for Modules
 */
import { Card } from '@unionco/components'
import tw, { TwStyle } from 'twin.macro'

import {
  TFlowInnerSpacingClass,
  TPaddingTokenClass
} from '@unionco/alaris-app-types'

import { cx } from '@unionco/utils'

export interface IModuleCardBodyProps {
  bgColorClass?: TwStyle
  bgClassName?: string
  children: React.ReactNode
  className?: string
  flowspace?: TFlowInnerSpacingClass
  padding?: TPaddingTokenClass | null
  innerCardPadding?: TPaddingTokenClass | null
  useNewStyling?: boolean
}

export const ModuleCardBody: React.FC<IModuleCardBodyProps> = ({
  bgColorClass = tw`u-bg-white`,
  children,
  className,
  bgClassName,
  flowspace = 'u-flow-space-inner--400',
  padding = 'u-p-500 print:u-p-0',
  innerCardPadding = 'u-p-300', // Set to even padding by default
  useNewStyling = false,
}) => {
  if (useNewStyling) {
    padding = 'u-p-200 print:u-p-0'
    return (
      <Card
        className={cx('c-flow', flowspace, padding, className)}
        bgColorClass={tw`u-bg-opacity-40`}
        cssClasses={[tw`u-rounded-[32px] u-border-2 u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`]}
      >
        <Card
          className={cx('c-flow', flowspace, innerCardPadding, className)}
          bgColorClass={tw`u-bg-opacity-60`}
          cssClasses={[tw`u-rounded-[24px] u-border-2 u-border-solid u-border-newprimary-purewhite u-backdrop-blur-[100px]`]}
        >
          {children}
        </Card>
      </Card>
    );
  }

  // Return old style card
  return (
    <Card
      className={cx('c-flow', flowspace, padding, className)}
      bgColorClass={bgClassName ? tw`u-bg-primary-300` : bgColorClass}
    >
      {children}
    </Card>
  )
}

export default ModuleCardBody
