import { StarAccent } from '@unionco/components'
import tw from 'twin.macro'

import { cx } from '@unionco/utils'

interface ILabelAndInputClasses {
  headerContent: string
  label: string
}

interface ILabelAndInputProps {
  children: React.ReactNode
  className?: string
  classNames?: Partial<ILabelAndInputClasses>
  label: string
  required?: boolean
  headerContent?: React.ReactNode
}

export const LabelAndInput: React.FC<ILabelAndInputProps> = ({
  children,
  className,
  classNames = {},
  headerContent,
  label,
  required
}) => {
  return (
    <div
      className={cx(
        'c-flow | u-flow-space-inner--300 u-items-start',
        className
      )}
    >
      <div className='c-dep-repel u-w-full'>
        <label
          className={cx(
            'c-cluster u-font-open-sans u-text-3 u-uppercase u-text-secondary-800 [--cluster-gutter:.25rem]',
            classNames.label
          )}
        >
          {label}
          {required && <StarAccent colorClassCSS={tw`u-text-state-danger`} />}
        </label>
        {headerContent}
      </div>
      {children}
    </div>
  )
}

export default LabelAndInput
