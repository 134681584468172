import { StarAccent } from '@unionco/components'
import tw from 'twin.macro'

import { StyledButton } from 'components/generic'

interface IEntryControlsProps {
  deleteEntry: (i: number) => void
  index: number
  saveEntry: () => void
}

export const EntryControls: React.FC<IEntryControlsProps> = ({
  deleteEntry,
  index,
  saveEntry
}) => {
  return (
    <div className='c-dep-repel'>
      <p className='o-body-1'>
        <StarAccent colorClassCSS={tw`u-text-state-danger`} /> Denotes Required
      </p>
      <div className='c-cluster'>
        <StyledButton
          type='button'
          theme='dark'
          themeStyle='ghost'
          onClick={() => deleteEntry(index)}
        >
          Delete Entry
        </StyledButton>
        <StyledButton
          type='button'
          theme='primary'
          themeStyle='outline'
          onClick={() => saveEntry()}
        >
          Save Changes
        </StyledButton>
      </div>
    </div>
  )
}

export default EntryControls
