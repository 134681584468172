/** Wayfinder AccordionButton
 *  Top Level Accordion Item for the wayfinder links to a section
 *  For Phase related items this either a live session or questionnaire
 *  For the Support section this is a
 */
import { Accordion, AccordionContext } from '@unionco/components'
import { ChevronIcon } from '@unionco/svg-icons'
import { useContext } from 'react'
import { useEffectOnce } from 'usehooks-ts'

import type {
  ISectionStatus,
  TIconName,
  TLiveSessionStatus,
  TPhaseStepType,
  TSectionType
} from '@unionco/alaris-app-types'

import { cx } from '@unionco/utils'
import { getSidebarRoute } from 'components/SidebarTemplate/util'
import { getPhaseStepPercent } from 'utils'

import { useNavigationClickTracking } from 'hooks/useNavigationClickTracking'

import { SectionStatusIndicator, SidebarContext } from 'components'

import StepLink from './StepLink'

export interface IWayfinderAccordionButtonProps {
  icon: TIconName
  index: number
  locked: boolean
  type: TSectionType
  sectionKey: string
  status: ISectionStatus | TLiveSessionStatus | null
  subtext?: string
  title: string
}

export const WayfinderAccordionButton: React.FC<
  IWayfinderAccordionButtonProps
> = ({ icon, index, locked, sectionKey, status, subtext, title, type }) => {
  const { toggleAccordionItems } = useContext(AccordionContext)
  const { pageLocations, pageRoute } = useContext(SidebarContext)
  const { navTrackingOnClick } = useNavigationClickTracking()

  const content = subtext ? (
    <div className={'c-flow | u-flow-space--100'}>
      <h4 className='o-h4'>{title}</h4>
      <p className='o-body-1'>{subtext}</p>
    </div>
  ) : (
    <h4 className='o-h4'>{title}</h4>
  )

  const alignment = subtext ? 'u-items-start' : 'u-items-center'
  const route = getSidebarRoute(pageRoute, sectionKey, 0, null)
  const isActiveClass =
    index === pageLocations['section'] ? ' u-bg-grayscale-700' : ''

  const percent = status
    ? getPhaseStepPercent(type as TPhaseStepType, status)
    : null

  const btnHoverClass = locked
    ? 'u-cursor-not-allowed'
    : 'hover:u-bg-grayscale-700'
  const chevronHoverClass = locked ? '' : 'hover:u-text-secondary-500'

  /**
   * [@seandepottey]
   * I may have negated the need for this with a new prop
   * on accordions, initalOpenItems
   */
  useEffectOnce(() => {
    if (index === pageLocations['section']) {
      toggleAccordionItems(index)
    }
  })

  return (
    <div
      className={cx(
        `b-WayfinderAccordionButton | u-relative u-flex  u-items-stretch`,
        btnHoverClass,
        isActiveClass
      )}
    >
      <StepLink
        href={route}
        locked={locked}
        alignment={alignment}
        onClick={() => navTrackingOnClick(title, 'sidebar')}
      >
        <SectionStatusIndicator percent={percent} icon={icon} type={type} />
        {content}
      </StepLink>
      {type !== 'liveSession' && (
        <Accordion.Button
          index={index}
          className={`u-p-400 ${chevronHoverClass}`}
          locked={locked}
        >
          <Accordion.Indicator index={index} animation='downToUp'>
            <ChevronIcon iconStyle='solid' iconDirection='down' />
          </Accordion.Indicator>
        </Accordion.Button>
      )}
    </div>
  )
}

export default WayfinderAccordionButton
