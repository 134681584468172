import { v4 as uuidv4 } from 'uuid'

import { cx } from '@unionco/utils'

import { AuthBGUI } from 'components/Auth'
import { Logo } from 'components/svg'

type TPossibleError = Error | undefined

type TErrorUIProps =
  | {
    error: TPossibleError | TPossibleError[]
    text?: undefined
    page?: boolean
  }
  | { error?: undefined; text: string; page?: undefined }

export const ErrorUI: React.FC<TErrorUIProps> = ({ error, page, text }) => {
  let serverError: JSX.Element | JSX.Element[] = <></>
  const errorTextStyling = 'u-text-state-danger u-text-5'

  if (Array.isArray(error)) {
    error.forEach((err) => {
      if (err) {
        console.error(err)
      }
    })
    serverError = error.map((err) => {
      return <p className={`o-body-1 ${errorTextStyling}`} key={uuidv4()}>{`${err}`}</p>
    })
  } else {
    serverError = <p className={`o-body-1 ${errorTextStyling}`}>{`${error}`}</p>
    console.error(error)
  }

  const sizeClasses = page ? 'u-min-h-screen u-w-screen' : ''

  return text ? (
    <AuthBGUI>
      <div
        className={cx(
          'c-box c-flow u-relative u-items-center u-justify-center u-pt-200 md:u-pt-400 lg:u-pt-500 xl:u-pb-800 xl:u-pt-800',
          sizeClasses
        )}
      >
        <div className='c-flow | u-text-center'>
          <div className='u-mb-600 u-px-600 u-pt-700'>
            <Logo className='u-w-full' />
          </div>
          <h2>Oops, something went wrong</h2>
          <div className={errorTextStyling}>{text}</div>
        </div>
      </div>
    </AuthBGUI>
  ) : (
    <AuthBGUI>
      <div
        className={cx(
          'c-box c-flow u-relative u-items-center u-justify-center u-pt-200 md:u-pt-400 lg:u-pt-500 xl:u-pb-800 xl:u-pt-800',
          sizeClasses
        )}
      >
        <div className='c-flow | u-text-center'>
          <div className='u-mb-600 u-px-600 u-pt-700'>
            <Logo className='u-w-full' />
          </div>
          <h2 className='o-h2'>Oops, something went wrong</h2>
          <div>{serverError}</div>
        </div>
      </div>
    </AuthBGUI>
  )
}

export default ErrorUI
