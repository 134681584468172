import type { IPointsOfAlignmentData } from '@unionco/alaris-app-types'

import { ModuleCard, TitleAndCopy } from 'components'

interface IPointsOfAlignmentProps {
  data: IPointsOfAlignmentData
}

export const PointsOfAlignment: React.FC<IPointsOfAlignmentProps> = ({
  data: { copy, info, items, title }
}) => {
  if (!items || items.length === 0) return <></>

  return (
    <ModuleCard.Body>
      <ModuleCard.InfoHeader
        info={info}
        title={title || 'Points Of Alignment'}
        trackingName='points of alignment module'
      />
      <p className='o-body-1 print:u-break-inside-avoid'>{copy}</p>
      <div className='c-autoGrid print:u-break-inside-avoid-page'>
        {items.map((item, index) => {
          const { title, value } = item
          return (
            <TitleAndCopy
              copy={value}
              title={title}
              key={`PointsOfAlignment_${index}`}
            />
          )
        })}
      </div>
    </ModuleCard.Body>
  )
}

export default PointsOfAlignment
